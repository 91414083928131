import "./Home.scss";
import React, { useEffect, useRef } from "react";
import HomeGlassIcon from "../../assets/icons/home-icon1.png";
import SettingsGlassIcon from "../../assets/icons/home-icon2.png";
import StarGlassIcon from "../../assets/icons/home-icon3.png";
import UnitGlassIcon from "../../assets/icons/home-icon4.png";
import HomeBannerBg1 from "../../assets/images/banner-img-1.png";
import HomeBannerBg2 from "../../assets/images/banner-img-2.png";
import HomeBannerBg3 from "../../assets/images/homeBanner3.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MilestoneCalender from "../../assets/images/milestone-calender.svg";
import MilestoneClient from "../../assets/images/milestone-client.svg";
import MilestoneLocation from "../../assets/images/milestone-location.svg";
import MilestoneProject from "../../assets/images/milestone-project.svg";
import Logo1 from "../../assets/images/goldplus.png";
import Logo2 from "../../assets/images/gg.png";
import Logo3 from "../../assets/images/ais.png";
import Logo4 from "../../assets/images/sg.png";
import Logo5 from "../../assets/images/dow.png";
import Logo6 from "../../assets/images/kuraray.png";
import Logo7 from "../../assets/images/saflex.png";
import Logo8 from "../../assets/images/fenzi.png";
import Logo9 from "../../assets/images/vanceva.png";
import CalcImg from "../../assets/images/Calc.png";
import Product1 from "../../assets/images/toughen-tempered.jpg";
import Product2 from "../../assets/images/heat-strengthened.jpg";
import Product3 from "../../assets/images/laminated.jpg";
import Product4 from "../../assets/images/dgu.jpg";
import AnimatedSection from "../../components/Animation/AnimatedSection";
import OurClient from "./OurClient/OurClient";
import { useNavigate } from "react-router-dom";
import CloudImg from "../../assets/images/cloud-img.png";
import SquareFt from "../../assets/icons/square-ft.svg";
import { useMediaQuery } from "@mui/material";
import MobileBanner2IMg from "../../assets/images/m-banner-2-img.png";

function Home() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1024px)");

  const videoRef = useRef(null);
  const scrollSectionRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fade: true,
  };

  useEffect(() => {
    const playbackConst = 600; // Adjust the constant as needed

    function scrollPlay() {
      if (videoRef.current) {
        const frameNumber = window.scrollY / playbackConst;
        videoRef.current.currentTime = frameNumber;

        const videoEle = document.getElementById("v0");

        if (
          Math.floor(frameNumber) > Math.floor(videoRef.current.duration) ||
          Math.floor(frameNumber) === Math.floor(videoRef.current.duration)
        ) {
          if (videoEle) {
            videoEle.style.position = "unset";
            videoEle.style.top = "unset";
          }
        }
      }
      window.requestAnimationFrame(scrollPlay);
    }

    window.requestAnimationFrame(scrollPlay);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 700) {
        const videoEle = document.getElementById("v0");
        if (videoEle) {
          videoEle.style.position = "fixed";
          videoEle.style.top = "20px";
        }
      }
      if (window.scrollY === 700 || window.scrollY === 0) {
        const videoEle = document.getElementById("v0");
        if (videoEle) {
          videoEle.style.position = "unset";
          videoEle.style.top = "unset";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.addEventListener("loadedmetadata", () => {
        const { duration } = video;
        const playbackConst = 600;
        const scrollSection = scrollSectionRef.current;

        if (videoRef?.current) {
          scrollSection.style.height =
            Math.floor(duration) * playbackConst + "px";
        }
      });

      return () => {
        video.removeEventListener("loadedmetadata", () => {});
      };
    }
  }, []);

  return (
    <div className="main-home-wrapper">
      <div className="home-page-content-wrapper">
        <div className="slider-alignment" id="homepage-banner">
          <Slider {...settings}>
            <div className="home-banner-wrapper">
              <div className="banner-content-wrapper">
                <img className="banner-image" src={HomeBannerBg1} alt="" />
                <div className="left-side-text-wrapper">
                  <div className="banner-header-text">
                    <div className="text-details-alignment">
                      <h1>
                        Step into a world <br />
                        where glass becomes art
                      </h1>
                      <p>
                        At JGS, we're not just shaping spaces; we're shaping
                        experiences
                      </p>
                      <div className="button-alignment">
                        <button onClick={() => navigate("/products")}>
                          Explore our products
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mobile-view-banner-alignment first-banner-mobile">
                  <img src={HomeBannerBg1} alt="HomeBannerBg1" />
                </div>
              </div>

              <div className="cloud-img-alignment">
                <img src={CloudImg} alt="CloudImg" />
              </div>
              <div className="cloud-img-alignment2">
                <img src={CloudImg} alt="CloudImg" />
              </div>
              <div className="cloud-img-alignment3">
                <img src={CloudImg} alt="CloudImg" />
              </div>
            </div>

            <div className="home-banner-wrapper">
              <div className="banner-content-wrapper">
                <img className="banner-image" src={HomeBannerBg2} alt="" />
                <div className="second-banner-alignment ">
                  <div className="banner-header-text">
                    <div className="text-details-alignment">
                      <h1 className="heading-max-alignment">
                        Make your glass
                        <br />
                        an investment, not a burden
                        <br />
                      </h1>
                      <p>
                        Our glass calculator helps you choose the best glass for
                        your project
                      </p>
                      <div className="button-alignment">
                        <button
                          onClick={() => navigate("/smart-glass-calculator")}
                        >
                          Find your glass
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="right-img-alignment">
                    <img src={CalcImg} alt="CalcImg" />
                  </div>
                </div>
                <div className="mobile-view-banner-alignment second-banner-mobile">
                  <img src={MobileBanner2IMg} alt="MobileBanner2IMg" />
                </div>
              </div>
            </div>

            <div className="home-banner-wrapper">
              <div className="banner-content-wrapper">
                <img className="banner-image" src={HomeBannerBg3} alt="" />
                <div className="left-side-text-wrapper">
                  <div className="banner-header-text">
                    <div
                      className="text-details-alignment"
                      style={
                        isMobile
                          ? {
                              maxWidth: "360",
                              width: "360",
                            }
                          : {
                              maxWidth: "710px",
                              width: "710px",
                            }
                      }
                    >
                      <h1
                        style={
                          isMobile
                            ? {
                                maxWidth: "360px",
                              }
                            : {
                                maxWidth: "710px",
                              }
                        }
                      >
                        Where clarity <br /> meets craftsmanship
                      </h1>
                      <p>
                        Crafted for perfection, discover the ultimate glass
                        quality with JGS
                      </p>
                      <div className="button-alignment">
                        <button onClick={() => navigate("/projects")}>
                          Explore our projects
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-view-banner-alignment second-banner-mobile">
                  <img src={HomeBannerBg3} alt="HomeBannerBg3" />
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {!isMobile && (
          <>
            <div>
              <div ref={scrollSectionRef} id="scrollSection">
                <video ref={videoRef} id="v0" preload="auto" muted>
                  <source
                    type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                    src="https://s3-content-purpose.s3.ap-south-1.amazonaws.com/Jgs+Text+Final.mp4"
                  ></source>
                </video>
              </div>
            </div>
          </>
        )}

        <div className="our-milestone-wrapper">
          <div className="container">
            <h2 className="home-section-title">Our Milestone</h2>
            <div className="our-milestone-grid-wrapper">
              <div className="our-milestone-grid-item">
                <AnimatedSection animationType="flip-left" duration="900">
                  <div className="our-milestone-icon">
                    <img src={MilestoneCalender} alt="" />
                  </div>
                </AnimatedSection>
                <p className="milestone-title">2010 Year</p>
                <p className="milestone-desc">of Establishment</p>
              </div>
              <div className="our-milestone-grid-item">
                <AnimatedSection animationType="flip-left" duration="900">
                  <div className="our-milestone-icon">
                    <img src={MilestoneProject} alt="" />
                  </div>
                </AnimatedSection>
                <p className="milestone-title">500+ Projects</p>
                <p className="milestone-desc">Completed</p>
              </div>
              <div className="our-milestone-grid-item">
                <AnimatedSection animationType="flip-left" duration="900">
                  <div className="our-milestone-icon">
                    <img src={MilestoneClient} alt="" />
                  </div>
                </AnimatedSection>
                <p className="milestone-title">250+</p>
                <p className="milestone-desc">Clients</p>
              </div>
              <div className="our-milestone-grid-item">
                <AnimatedSection animationType="flip-left" duration="900">
                  <div className="our-milestone-icon">
                    <img src={MilestoneLocation} alt="" />
                  </div>
                </AnimatedSection>
                <p className="milestone-title">18+</p>
                <p className="milestone-desc">States</p>
              </div>
              <div className="our-milestone-grid-item">
                <AnimatedSection animationType="flip-left" duration="900">
                  <div className="our-milestone-icon">
                    <img src={SquareFt} alt="" />
                  </div>
                </AnimatedSection>
                <p className="milestone-title">1.5lac</p>
                <p className="milestone-desc">Sq. ft.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="our-projects-wrapper">
          <div className="container">
            <h2 className="home-section-title">Work Experiences</h2>
            <div className="projects-grid-wrapper">
              <div className="project-grid-item">
                <div className="bg-1">
                  <div
                    className="project-title-wrapper"
                    onClick={() => {
                      localStorage.setItem("project", 0);
                      navigate("/projects");
                    }}
                  >
                    <p>Residential</p>
                  </div>
                </div>
              </div>

              <div className="project-grid-item">
                <div className="bg-2">
                  <div
                    className="project-title-wrapper"
                    onClick={() => {
                      localStorage.setItem("project", 1);
                      navigate("/projects");
                    }}
                  >
                    <p>Commercial</p>
                  </div>
                </div>
              </div>

              <div className="project-grid-item">
                <div className="bg-3">
                  <div
                    className="project-title-wrapper"
                    onClick={() => {
                      localStorage.setItem("project", 2);
                      navigate("/projects");
                    }}
                  >
                    <p>Institutional</p>
                  </div>
                </div>
              </div>

              <div className="project-grid-item">
                <div className="bg-4">
                  <div
                    className="project-title-wrapper"
                    onClick={() => {
                      localStorage.setItem("project", 3);
                      navigate("/projects");
                    }}
                  >
                    <p>Leisure</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-us-wrapper">
          <div className="container">
            <h2
              className="home-section-title"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate("/about-us")}
            >
              About Us
            </h2>
            <div className="about-us-content-wrapper">
              <div className="about-us-details">
                <p>
                  Welcome to JGS Glass Industries - Your destination for all
                  your architectural glass needs since 2010.
                </p>
                <p>
                  But let's rewind a bit. Back in 1992, we dipped our toes into
                  the glass game. Fast forward to today, and we've grown into
                  four sister companies, offering a one-stop shop for all your
                  glass needs.
                </p>
                <p>
                  We started small, as a humble glass retail shop. But with
                  determination and hard work, we've become a powerhouse in the
                  industry.
                </p>
              </div>
              <div className="about-us-grid">
                <div className="about-us-grid-item">
                  <div className="icon-wrapper">
                    <img src={HomeGlassIcon} alt="" />
                  </div>
                  <div className="content-alignment">
                    <p className="content-title">Jain Glass</p>
                    <p className="content-description">
                      Small glass retail shop in surat
                    </p>
                  </div>
                </div>

                <div className="about-us-grid-item">
                  <div className="icon-wrapper">
                    <img src={SettingsGlassIcon} alt="" />
                  </div>
                  <div className="content-alignment">
                    <p className="content-title">1995</p>
                    <p className="content-description">
                      Embraced cutting-edge machinery in 1995
                    </p>
                  </div>
                </div>

                <div className="about-us-grid-item">
                  <div className="icon-wrapper">
                    <img src={UnitGlassIcon} alt="" />
                  </div>
                  <div className="content-alignment">
                    <p className="content-title">2010</p>
                    <p className="content-description">
                      Birth of Jain Glass Solutions in 2010
                    </p>
                  </div>
                </div>

                <div className="about-us-grid-item">
                  <div className="icon-wrapper">
                    <img src={StarGlassIcon} alt="" />
                  </div>
                  <div className="content-alignment">
                    <p className="content-title">2023</p>
                    <p className="content-description">
                      Birth of JGS Glass Industries in 2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-market-wrapper">
          <div className="container">
            <h2
              className="home-section-title"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate("/products")}
            >
              Our Products
            </h2>
            <div className="our-market-grid">
              <div className="market-grid-item-wrapper">
                <AnimatedSection animationType="fade" duration="900">
                  <div className="market-grid-item">
                    <img src={Product1} alt="Toughened/Tempered Glass" />
                  </div>
                </AnimatedSection>
                <p onClick={() => navigate("/products")}>
                  Toughened/Tempered <br /> Glass
                </p>
              </div>
              <div className="market-grid-item-wrapper">
                <AnimatedSection
                  animationType="fade"
                  duration="900"
                  delay="10000"
                >
                  <div className="market-grid-item">
                    <img src={Product2} alt="Heat Strengthened Glass" />
                  </div>
                </AnimatedSection>
                <p onClick={() => navigate("/products")}>
                  Heat Strengthened <br /> Glass
                </p>
              </div>
              <div className="market-grid-item-wrapper">
                <AnimatedSection
                  animationType="fade"
                  duration="900"
                  delay="20000"
                >
                  <div className="market-grid-item">
                    <img src={Product4} alt="DGUs/IGUs (Argon Filled)" />
                  </div>
                </AnimatedSection>
                <p onClick={() => navigate("/products")}>
                  DGUs/IGUs <br /> (Argon Filled)
                </p>
              </div>
              <div className="market-grid-item-wrapper">
                <AnimatedSection
                  animationType="fade"
                  duration="900"
                  delay="20000"
                >
                  <div className="market-grid-item">
                    <img src={Product3} alt="Laminated Glass" />
                  </div>
                </AnimatedSection>
                <p onClick={() => navigate("/products")}>
                  Laminated <br />
                  Glass
                </p>
              </div>
            </div>
          </div>
        </div>

        <OurClient />

        <div className="our-alliance-wrapper">
          <div className="container">
            <h2 className="home-section-title">Our Alliance</h2>
            <AnimatedSection animationType="fade-up" duration="900">
              <div className="our-alliance-logo-wrapper">
                <div className="logo-wrapper">
                  <img
                    src={Logo1}
                    alt="Logo1"
                    style={{
                      height: "58px",
                    }}
                  />
                </div>
                <div className="logo-wrapper">
                  <img
                    src={Logo2}
                    alt="Logo2"
                    style={{
                      height: "38px",
                    }}
                  />
                </div>
                <div className="logo-wrapper">
                  <img
                    src={Logo3}
                    alt="Logo3"
                    style={{
                      height: "56px",
                    }}
                  />
                </div>
                <div className="logo-wrapper">
                  <img
                    src={Logo4}
                    alt="Logo4"
                    style={{
                      height: "52px",
                    }}
                  />
                </div>
              </div>

              <div className="our-alliance-logo-wrapper">
                <div className="logo-wrapper">
                  <img
                    src={Logo5}
                    alt="Logo5"
                    style={{
                      height: "42px",
                    }}
                  />
                </div>
                <div className="logo-wrapper">
                  <img
                    src={Logo6}
                    alt="Logo6"
                    style={{
                      height: "34px",
                    }}
                  />
                </div>
                <div className="logo-wrapper">
                  <img
                    src={Logo7}
                    alt="Logo7"
                    style={{
                      height: "34px",
                    }}
                  />
                </div>
                <div className="logo-wrapper">
                  <img
                    src={Logo8}
                    alt="Logo8"
                    style={{
                      height: "38px",
                    }}
                  />
                </div>
                <div className="logo-wrapper">
                  <img
                    src={Logo9}
                    alt="Logo9"
                    style={{
                      height: "22px",
                    }}
                  />
                </div>
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
